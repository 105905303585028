import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "fog" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  style: {"margin-bottom":"20px"}
}
const _hoisted_5 = ["innerHTML"]

import { ref, onMounted } from 'vue';
import anime from 'animejs/lib/anime.es.js';
import { fetchNewsItems, NewsItem } from '@/services/contentfulService';


export default /*@__PURE__*/_defineComponent({
  __name: 'News',
  setup(__props) {

const newsItems = ref<NewsItem[]>([]);
const activeNews = ref(1);

const toggleNews = () => {
  const previousNews = activeNews.value;
  activeNews.value = (activeNews.value % newsItems.value.length) + 1;

  const inDirection = 500;
  const outDirection = -500;

  anime({
    targets: `.news#News${activeNews.value}`,
    translateX: [inDirection, 0],
    opacity: [0, 1],
    duration: 1000,
    easing: 'easeOutQuad'
  });

  anime({
    targets: `.news#News${previousNews}`,
    translateX: [0, outDirection],
    opacity: [1, 0],
    duration: 1000,
    easing: 'easeOutQuad'
  });
};

onMounted(async () => {
  newsItems.value = await fetchNewsItems();

  setTimeout(() => {
    anime.timeline()
        .add({
          targets: '.logo',
          translateY: [-100, 50], // Move into view
          opacity: [0, 1], // Fade in
          duration: 1000,
          easing: 'easeOutQuad'
        })
        .add({
          targets: '.logo',
          translateY: [50, 40], // Bounce down
          duration: 500,
          easing: 'easeOutCubic'
        });
  }, 1000);

  setTimeout(() => {
    anime.timeline()
        .add({
          targets: '.logoText',
          translateX: [-200, 0], // Move into view from the left
          opacity: [0, 1], // Fade in
          duration: 1000,
          easing: 'easeOutQuad' // Smooth animation
        })
        .add({
          targets: '.footer img',
          translateY: [300, -10], // Move into view
          opacity: [0, 1], // Fade in
          duration: 500,
          easing: 'easeOutQuad'
        })
        .add({
          targets: '.footer img',
          translateY: [-10, 0], // Bounce down
          duration: 250,
          easing: 'easeOutCubic'
        });
  }, 2000);

  setInterval(toggleNews, 30000);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "absolute-bg" }, null, -1)),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "header" }, [
      _createElementVNode("img", {
        class: "logo",
        src: "/logo.png",
        alt: ""
      }),
      _createElementVNode("img", {
        class: "logoText",
        src: "/logo-text.png",
        alt: ""
      })
    ], -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(newsItems.value, (news, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass(['news', { active: activeNews.value === index + 1 }]),
        id: 'News' + (index + 1)
      }, [
        _createElementVNode("img", {
          class: "newsImage",
          src: news.image,
          alt: ""
        }, null, 8, _hoisted_3),
        _createElementVNode("h1", null, _toDisplayString(news.title), 1),
        (news.quote)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
              _createElementVNode("strong", null, _toDisplayString(news.quote), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("p", {
          innerHTML: news.description
        }, null, 8, _hoisted_5)
      ], 10, _hoisted_2))
    }), 128)),
    _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"footer\"><img src=\"/wifi.png\" alt=\"\"></div><div class=\"fog-container\"><div class=\"fog-img fog-img-first\"></div><div class=\"fog-img fog-img-second\"></div></div>", 2))
  ]))
}
}

})