import { createClient } from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

const client = createClient({
    space: '4oz7qurv6zhw',
    accessToken: 'iw-QKBU9MiojKmagXFwRGH6xcN64JURitIBW64Trgtg',
});

export interface NewsItem {
    image: string;
    title: string;
    description: string;
    quote?: string;
}

export async function fetchNewsItems(): Promise<NewsItem[]> {
    const response = await client.getEntries({ content_type: 'article' });

    return response.items.map((item: any) => ({
        image: item.fields.image.fields.file.url,
        title: item.fields.headline,
        description: documentToHtmlString(item.fields.body),
        quote: item.fields.quote,
    }));
}