<template>
  <router-view/>
</template>
<style>
html,
body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.montserrat-normal {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

#app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
</style>
