<template>
  <section class="fog">
    <div class="absolute-bg"></div>
    <div class="header">
      <img class="logo" src="/logo.png" alt="">
      <img class="logoText" src="/logo-text.png" alt="">
    </div>
    <div v-for="(news, index) in newsItems" :key="index" :class="['news', { active: activeNews === index + 1 }]" :id="'News' + (index + 1)">
      <img class="newsImage" :src="news.image" alt="">
      <h1>{{ news.title }}</h1>
      <p style="margin-bottom: 20px;" v-if="news.quote"><strong>{{ news.quote }}</strong></p>
      <p v-html="news.description"></p>
    </div>
    <div class="footer">
      <img src="/wifi.png" alt="">
    </div>
    <div class="fog-container">
      <div class="fog-img fog-img-first"></div>
      <div class="fog-img fog-img-second"></div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import anime from 'animejs/lib/anime.es.js';
import { fetchNewsItems, NewsItem } from '@/services/contentfulService';

const newsItems = ref<NewsItem[]>([]);
const activeNews = ref(1);

const toggleNews = () => {
  const previousNews = activeNews.value;
  activeNews.value = (activeNews.value % newsItems.value.length) + 1;

  const inDirection = 500;
  const outDirection = -500;

  anime({
    targets: `.news#News${activeNews.value}`,
    translateX: [inDirection, 0],
    opacity: [0, 1],
    duration: 1000,
    easing: 'easeOutQuad'
  });

  anime({
    targets: `.news#News${previousNews}`,
    translateX: [0, outDirection],
    opacity: [1, 0],
    duration: 1000,
    easing: 'easeOutQuad'
  });
};

onMounted(async () => {
  newsItems.value = await fetchNewsItems();

  setTimeout(() => {
    anime.timeline()
        .add({
          targets: '.logo',
          translateY: [-100, 50], // Move into view
          opacity: [0, 1], // Fade in
          duration: 1000,
          easing: 'easeOutQuad'
        })
        .add({
          targets: '.logo',
          translateY: [50, 40], // Bounce down
          duration: 500,
          easing: 'easeOutCubic'
        });
  }, 1000);

  setTimeout(() => {
    anime.timeline()
        .add({
          targets: '.logoText',
          translateX: [-200, 0], // Move into view from the left
          opacity: [0, 1], // Fade in
          duration: 1000,
          easing: 'easeOutQuad' // Smooth animation
        })
        .add({
          targets: '.footer img',
          translateY: [300, -10], // Move into view
          opacity: [0, 1], // Fade in
          duration: 500,
          easing: 'easeOutQuad'
        })
        .add({
          targets: '.footer img',
          translateY: [-10, 0], // Bounce down
          duration: 250,
          easing: 'easeOutCubic'
        });
  }, 2000);

  setInterval(toggleNews, 30000);
});
</script>

<style>
.news {
  position: absolute;
  z-index: 1;
  top: 250px;
  width: 100%;
  opacity: 0;
  transform: translateX(-500px);
  transition: transform 2s ease, opacity 2s ease;
}

.news.active {
  opacity: 1;
  transform: translateX(0);
}

h1 {
  font-size: 4rem;
  color: #ffffff;
  text-align: center;
}

p {
  margin: 0 auto;
  font-size: 2rem;
  color: #ffffff;
  max-width: 70%;
  text-align: center;
}

.newsImage {
  display: block;
  border-radius: 20px;
  max-width: 70%;
  max-height: 300px;
  margin: 0 auto;
}

.footer {
  position: absolute;
  z-index: 1;
  width: 100%;
  bottom: 20px;
}

.footer img {
  max-width: 200px;
  margin: 0 auto;
  display: block;
  opacity: 0; /* Start hidden */
  transform: translateY(200px); /* Start above the page */
}

.logo {
  display: block;
  margin: 0 auto 20px;
  max-width: 400px;
  opacity: 0; /* Start hidden */
}

.logoText {
  display: block;
  margin: 50px auto;
  max-width: 500px;
  opacity: 0; /* Start hidden */
  transform: translateX(-200px); /* Start from the left off-screen */
}

.absolute-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../assets/img/crowd2.jpg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.title > h1 {
  color: #fff;
  font-size: 5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.fog {
  position: relative;
  height: 100vh;
  width: 100%;
  background: #fff;
}

.fog-container {
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 2;
}

.fog-img {
  position: absolute;
  height: 100vh;
  width: 300vw;
  z-index: 2;
}

.fog-img-first {
  background: url("../assets/img/fog-1.png");
  background-repeat: repeat-x;
  background-size: contain;
  background-position: center;
  animation: marquee 60s linear infinite;
}

.fog-img-second {
  background: url("../assets/img/fog-2.png");
  background-repeat: repeat-x;
  background-size: contain;
  background-position: center;
  animation: marquee 30s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-200vw, 0, 0);
  }
}

</style>
